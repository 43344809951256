@import url("https://fonts.googleapis.com/css?family=Raleway:400");
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Playfair+Display&family=Source+Sans+3:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&family=Lora&family=Playfair+Display&family=Source+Sans+3:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:900i');



/* @font-face {
  font-family: Twitch;
  src:url(Fonts/Twitch.ttf)
}

@font-face {
  font-family: Lato;
  src: url(Fonts/Lato-LightItalic.ttf);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "montserrat";
  color: #fff;
}

html,
body {
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}


body::-webkit-scrollbar {
  display: block;
  width: 8px;
  background: #000000;
}
body::-webkit-scrollbar-thumb {
  background-color: #fff;

  border-radius: 50px;
}
::selection{
  color: red;
  background: white;
}

#preloader{
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0);
  position: fixed;
  z-index: 10000;
  
  opacity: 1; /* Initial opacity */
  transition: opacity 0.5s ease;
}
.footer_div {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  font-size: 10px;
  background-color: #000000;
  text-align: center;
  z-index: 100;
  
}

.footer-distributed{
	background-color: rgba(0, 0, 0, 0.805);
  backdrop-filter: blur(5px);
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 40%;
}

/* The company logo */

.footer-logo{
  height: 100px;
  width: 100px;
  margin: 20px;

}

.footer-distributed h3{
	color:  #ffffff;
	
	margin: 0;
}

.footer-distributed h3 span{
	color:  rgb(178, 146, 32);
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-right i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 22px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 15px 0;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  rgb(194, 93, 20);
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
  margin-bottom: 10px;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

.responsive-map-container {
  position: relative;
  margin: auto;

  height: 0;
  overflow: hidden;
  height: 30vh;
  width: 250px
}

.responsive-map-container iframe,   
.responsive-map-container object,  
.responsive-map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}


.logo{
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100vh;
  width: 100vw;
}

.logo svg{

  
    width: 200px; /* Set the desired width */
    height: 50x; /* Set the desired height */
    position: relative;
   
  }
body {
  overflow-x: hidden;
}



#nav {
  height: 130px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  padding: 0 120px;
  gap: 50px;
  position: fixed;
  justify-content:center;
  z-index: 999;
}

#nav li{
  padding-top: 15px;
  list-style: none;
}

#nav .links{
  display: flex;
  gap: 80px;
} 


#nav img {
  vertical-align: top;
  height: 60px;
  
  
}
#nav h4 {
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}

#nav a{
  text-decoration: none;
}
#nav h4:hover{
  color: #d38966;
}




 .toggle_btn{
  display: none;
  

}

.dropdown{
  display: hidden;
  position: fixed;
  
  top: 100px;
  height: 0;
  width: 300px;
  background: rgba(31, 22, 5, 0.053);
  backdrop-filter: blur(15px);
  overflow: hidden;
  transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

}
.dropdown.open{
  height: 240px;
  z-index: 999;
}

.dropdown li{
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1025px) {

  #nav li{
    display: none;
  }

  .dropdown a{
    text-decoration: none;
  }
  #nav img{   
    height: 50px;
  }

  .toggle_btn{

    display:block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    font-size: 24px;
  }

  .dropdown{ 
  width: 100%;


  }

  
  

}







video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  position: fixed;
}

#main {
  position: relative;
  background-color: rgba(0, 0, 0, 0.39);
}


#page1 {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  z-index: 10;
}
svg{
  
  width: 400px; /* Set the desired width */
  height: 50x; /* Set the desired height */
  position: relative;
 
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 200vh;
  width: 75vw;
}

#page1 .container .text {
  font-size: 100px;
  letter-spacing: .01em;
  line-height: 100%;
  margin: 0;
  font-family: 'Anton', sans-serif;
  width: 100%;
  background: linear-gradient(to right, #b6b6b6, #b6b6b6) no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 0%;
  

  border-bottom: 1px solid #2F2B28;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container span {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e31818;
  color: #fffdfd;
  font-family:  'Anton', sans-serif;
  clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
  transform-origin: center;
  transition: all cubic-bezier(.1,.5,.5,1) 0.4s;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container .text:hover > span {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.container a {
  text-decoration: none;
  color: inherit;
}


#page1 h1 {
  font-size: 8vw;
  font-weight: 800; /* Use a higher value for bolder text */
  position: relative;

  
}



#page1 h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 10px;

}
#page1 p {
  font-size: 1.2vw;
  font-weight: 500;
  width: 40%;
}

#page2 {
  
  position: relative;
  width: 100%;
  z-index: 100;
  
  
}





@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}





#space{
  height:50px;
}


@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}


:root {
	--d: 6500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
  --c1: rgb(254, 195, 57);   /* Fire Color 1 (Red) */
  --c2: rgba(172, 118, 17, 0.053);
}


#about-us {
  
  height: 100vh;
  width: 100%;
  margin-top: 10vh;
  display: flex;
 

  align-items: center;
  position: relative;
  z-index: 10;
  justify-content: space-around;
}


#about-us-in {
  width: 80%;
  text-align: center;
  margin: 20px; /* Add margin here */
  border: 0.15rem solid;
  padding: 5vh 10vw;
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}



@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}

#about-us-in h3 {
  font-size: 4vw;
  /* font-weight: 800; */
  margin-bottom: 30px;
  letter-spacing: 2px;
  font-family: 'Anton', sans-serif;

}
#about-us-in p {
  font-size: 20px;
  font-family: 'Josefin Sans', sans-serif;
}


/*----------------- EVENTS ---------------------*/
#events{
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 10vh 50vw;
}

.container-container {
  display: flex;
  flex-direction: row;
  gap: 12px;

}

.container-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.container-container-mobile{
  display: none;
}
.container {
  position: relative;
  height: 50vh;
  width: 40vw;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.25s;
}

.container-mobile {
  position: relative;
  margin-top: 2vh;
  margin-bottom: 2vh;
  /* height: 50vh; */
  width: 75vw;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.25s;
}

.image-container, .image-container-mobile {
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.25s;
  cursor: pointer;
}

.overlay {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: filter 1.3s;
}

.image-box, .image-box-mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  filter: blur(3px);
  transition: filter .5s;
}

.agility {
  background-image: url('media/agility/img1.jpg');
  background-position: center;
}

.spoorthi {
  background-image: url('media/spoorthi/img4.jpg');
}

.marathon {
  background-image: url('media/marathon/img3.jpg');
}

.achievements {
  background-image: url('media/achievements/img1.jpg');
}



.container:hover{
  border: 2px;
  border-color: black;
  transform: scale(1.1);
  z-index: 5;
}

.container-mobile:hover{
  border: 2px;
  border-color: black;
  transform: scale(1.1);
  z-index: 5;
}

.image-container:hover .image-box{
  filter: blur(0px);
}

.image-container:hover .overlay{
  filter: blur(3px);
  filter: opacity(80%);
}

.sponsors_tab{
    height: 40vh;
    width: 80vw;
    background-image: url(media/our_partner.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10vh;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}



/* -------------------------------------- */

@media screen and (max-width: 900px) {
  .container-container{
    display: none;

  }

  .container-container-mobile{
    display: block;
    
  
  }

  .container{
    height: 20vh;
    width: 85vw;
    padding-bottom: 10px;
  }

  #page1 .container .text {
    font-size: 8vh;

  }

  #about-us-in h3 {
    font-size: 30px;
    /* font-weight: 800; */
    margin-bottom: 30px;
  }
  .sponsors_tab{
    background-image: url(media/our_partner_phone.png) ;
    height: 40vh;
  
  }



}

.timeline h1{
  padding-top: 10vh;
  text-align: center;
  background-color: #FFA500;
}

.timeline ul {
  background: rgba(0, 0, 0, 0.39);
  padding-bottom: 10vh;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
 
  border:1px solid #6b6b6b;
  
  border-radius: 1em;
  padding: 2em;
  background-color: rgba(0, 0, 0, .5);
}

.timeline ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent rgb(254, 195, 57) transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent rgb(254, 195, 57);
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}


/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: rgb(254, 195, 57);
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}


/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 80px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent rgb(254, 195, 57) transparent transparent;
  }
}


/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}


/* .timeline {
  border: 0.35rem solid;
  margin: 5em auto;
  max-width: 34.15em;
}

.timeline h1{
  
}

.line {
  position: absolute;
  top: 50%;
  width: 4px;
  height: 100%;
  background-color: #fff;
}

.checkpoint{
  height: 30em;
  max-width: 34em;
  padding: 1em 0;
  display: flex;
  align-items: center;
  position: relative;
}



.checkpoint .text{
  border: 1px solid #6b6b6b;
  border-radius: 1em;
  padding: 2em;
  background-color: rgba(0, 0, 0, .5);
}

.checkpoint p {
  line-height: 27px;
  color: #fff;
}

.checkpoint:nth-child(odd) {
  padding-left: 3em;
  transform: translateX(17em);
}

.checkpoint:nth-child(even) {
  padding-right: 3em;
  transform: translateX(-17em);
}

.checkpoint:nth-child(odd) .text::before,
.checkpoint:nth-child(even) .text::before {
  content: '';
  background: #fff;
  width: 3em;
  height: 3px;
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
}

.checkpoint:nth-child(odd) .text::before {
  left: -3em;
}

.checkpoint:nth-child(even) .text::before {
  right: -3em;
}

.checkpoint:nth-child(odd) .line {
  left: -2px;
}

.checkpoint:nth-child(even) .line {
  right: -2px;
}

.checkpoint:nth-child(odd) .text::after,
.checkpoint:nth-child(even) .text::after {
  content: '';
  background: #fff;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
}

.checkpoint:nth-child(odd) .text::after {
  left: -57px;
}

.checkpoint:nth-child(even) .text::after {
  right: -57px;
}


@media screen and (max-width: 1150px) {
  .timeline {
      width: 80vw;
  }
  .timeline .checkpoint {
      width: 100%;
      transform: none;
      padding-left: 0;
      padding-right: 0;
  }
  .timeline .checkpoint .line{
      right: initial;
      left: 50%;
      transform: translateX(-50%);
  }
  .timeline .checkpoint .text::before {
      width: 3px;
      height: 4em;
      top: -4em;
      left: 50%;
      transform: translateX(-50%);
  }
  .timeline .checkpoint .text::after {
      top: -4.5em;
      left: 50%;
      transform: translateX(-50%);
  }
} */

/* --------------------------- FOOTER --------------------------- */
/* footer {
  background-color: #111;
  color: #fff;
  padding: 20px 10px;
  font-family: 'Louis_George_Cafe_Italic', sans-serif !important;
} */

/* .footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.footer-col {
  width: 48%; 
  margin-bottom: 20px;
}

.footer-col h1, .footer-col h4 {
  color: #fff;
  font-weight: bold;
}

.footer-col.socials {
  font-size: 16px;
}

.footer-col.socials a {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  text-decoration: none;
}

.footer-col.socials i {
  margin-right: 5px;
}

.footer-col.find-us {
  text-align: left;
  margin-bottom: 0;
}

.footer-col.map-container {
  width: 80%;
}

.footer-col.map iframe {
  width: 100%;
  height: 200px;
  border-radius: 5px;
} */

.committee-name {
  text-align: center;
  width: 100%;
  padding-top: 20px;
}


#end_page{
  height: 100vh;
}
